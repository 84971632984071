import React from 'react';


import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from 'react-apollo';

import { SocketProvider } from './utils/socketio';

import App from './App';

function Dorothy(props) {
    const cache = new InMemoryCache();
    const client = new ApolloClient({
        cache,
        link: new HttpLink({
            uri: `${props.url}/graphql`,
        }),
    });

    return <SocketProvider url={props.url}>
        <ApolloProvider client={client}>
            <App tools={props.tools}/>
        </ApolloProvider>
    </SocketProvider>;
}

export default Dorothy;