import React, { Fragment, Suspense } from 'react';
import { graphql, compose } from 'react-apollo';
import gql from "graphql-tag";

import pathParse from 'path-parse';

import tools from '../../tools/';

import {
    Alignment,
    Button,
    Classes,
    Navbar,
    NavbarGroup,
    NavbarHeading,
    Menu,
    MenuItem,
    Popover,
    Position,
} from "@blueprintjs/core";

import './styles.css';

function Community(props) {
    const { connected, location, data: { loading, error } } = props;

    const allTools = {...tools, ...props.tools};

    if (!connected) return <div>Connecting to server...</div>;
    if (loading) return <div>LOADING TOOLS INFO...</div>;
    if (error) return <div>ERROR!</div>;

    const viewsList = props.data.views.reduce((acc, view) => {return {...acc, [view.url]: view}}, {});

    let { base: currentView } = pathParse(location.pathname);
    if (currentView === '') currentView = Object.keys(viewsList)[0];

    console.log('CURRENT VIEW:', currentView);

    const handleToolChange = (view) => {
        props.navigate(`../${view}`);
    }

    const toolsMenu = (
        <Menu>
            {Object.values(viewsList).map(view => <MenuItem key={view.component} text={view.name} onClick={() => handleToolChange(view.url)} />)}
        </Menu>
    );

    const View = allTools[viewsList[currentView].component];
    return <Fragment>
        <Navbar className="community_navbar">
            <NavbarGroup align={Alignment.LEFT}>
                <NavbarHeading><div className="community_title">Genesis Community</div></NavbarHeading>
            </NavbarGroup>

            <NavbarGroup align={Alignment.RIGHT}>
                <Popover content={toolsMenu} position={Position.BOTTOM}>
                    <Button className={Classes.MINIMAL} icon="wrench" text="Ferramentas da comunidade" />
                </Popover>
            </NavbarGroup>
        </Navbar>

        <div className="community_container">
            <Suspense fallback={<div>Loading view...</div>}>
                <View />
            </Suspense>
        </div>
    </Fragment>;
}

export default compose(
    graphql(gql`
        query getViews {
            views {
                name
                component
                url
            }
        }
      `, {
        options: {
          // fetchPolicy: 'network-only'
        },
      }),
  )(Community);