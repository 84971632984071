import React, { Component } from 'react';

import {
    Alignment,
    ButtonGroup,
    Button,
    Classes,
    Navbar,
    NavbarGroup,
    NavbarHeading,
    Menu,
    MenuItem,
    Popover,
    Position,
} from "@blueprintjs/core";

import './styles.css';

import { FiAlertTriangle } from 'react-icons/fi';

class Header extends Component {
    render() {
        const communitiesMenu = (
            <Menu>
                <MenuItem icon="clean" text="Genesis" />
            </Menu>
        );

        const userMenu = (
            <Menu>
                <MenuItem icon="log-out" text="Sair" onClick={this.props.onLogout} />
            </Menu>
        );

        return <Navbar className="main_navbar">
            <NavbarGroup align={Alignment.LEFT}>
                <NavbarHeading className="navbar_h"></NavbarHeading>
                {this.props.connectionFail && <div className="connection_alert"><FiAlertTriangle /> connection lost...</div>}
            </NavbarGroup>

            <NavbarGroup align={Alignment.RIGHT}>
                <ButtonGroup>
                    <Popover content={communitiesMenu} position={Position.BOTTOM}>
                        <Button className={Classes.LARGE} rightIcon="caret-down" icon="graph" text="Comunidades" />
                    </Popover>

                    <Popover content={userMenu} position={Position.BOTTOM}>
                        <Button className={Classes.LARGE} rightIcon="caret-down" icon="user" />
                    </Popover>
                </ButtonGroup>
            </NavbarGroup>
        </Navbar>;
    }
}
export default Header;