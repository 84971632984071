import React from 'react';
import io from 'socket.io-client';

let socket;

export const SocketProvider = (props) => {
    socket = io(props.url);
    return <div>{props.children}</div>;
};

export const withSocket = (WrappedComponent) => {
    return class extends React.Component {
        render() {
            return <WrappedComponent {...this.props} socket={socket} />;
        }
    };
};