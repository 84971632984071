import React, { Component, Fragment } from 'react';
import { withSocket } from './utils/socketio';

import Header from './components/header';
import Community from './components/community';
import { Router, navigate } from "@reach/router";
import vhCheck from 'vh-check';
import { isMobile } from 'react-device-detect';
import { MdReportProblem } from 'react-icons/md';
import { ControlGroup, Button, FormGroup, InputGroup } from "@blueprintjs/core";
import './App.css';
import logo from './logo.png';

import './styles.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogged: false,
      connected: false,
      connectionFail: false,
    };

    const { socket } = this.props;

    socket.on('connect', () => this.onConnect());
    socket.on('disconnect', () => this.onDisconnect());
    socket.on('reconnect', () => this.onReconnect());
    socket.on('reconnect_error', () => this.onReconnectError());
  }

  async componentWillMount() {
    if (localStorage.getItem('username')) {
      this.props.socket.emit('add user', localStorage.getItem('username'));
      this.setState({
        isLogged: true,
        username: localStorage.getItem('username')
      });
    } else {
      await navigate('/login');
    }
  }

  componentDidMount() {
    // const test = vhCheck();
    this.setState({ style: { minHeight: '50vh' } });
  }

  render() {
    if (isMobile) return <div className="no_mobile">
      <h1><MdReportProblem /></h1>
      Esta fase do projeto n&atilde;o permite o uso em aparelhos m&oacute;veis!
    </div>;

    const { connectionFail } = this.state;

    return <div className="wrap" style={this.state.style}>
      <Router>

        <Login path="login" onLogin={this.handleLogin} />
        <Logged tools={this.props.tools} default connected={this.state.connected} connectionFail={connectionFail} onLogout={this.handleLogout} />

      </Router>
    </div>;
  }

  handleLogin = async (username) => {
    localStorage.setItem('username', username);
    await navigate('/');
    this.props.socket.emit('add user', username);
    this.setState({
      username,
      isLogged: true
    });
  }

  onConnect = () => {
    this.setState({ connected: true });
  }
  onDisconnect() {
    this.setState({ connectionFail: true });
  }
  onReconnect() {
    const { socket } = this.props;

    socket.emit('add user', this.state.username);
    this.setState({ connectionFail: false });
  }
  onReconnectError() {
    console.log('Reconnection Error...');
  }

  handleLogout = () => {
    this.props.socket.emit('remove user');
    localStorage.removeItem('username');

    navigate('/login');

    this.setState({
      username: undefined,
      isLogged: false
    });
  }
}

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
    };
  }

  render() {
    return <div className="login">
      <div className="login_container">

        <h1><img src={logo} alt="Dorothy Logo" /></h1>
        <FormGroup
          label="Digite seu e-mail"
          labelFor="username-input"
        >
          <ControlGroup>
            <InputGroup id="username-input" name="username" placeholder="E-mail" onChange={this.handleChange} onKeyDown={this.handleKDown} value={this.state.username} />
            <Button icon="arrow-right" onClick={this.handleClick}/>
          </ControlGroup>
        </FormGroup>

      </div>
    </div>;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleKDown = (e) => {
    if (e.keyCode === 13) {
      this.props.onLogin(this.state.username)
    }
  }

  handleClick = () => {
    this.props.onLogin(this.state.username)
  }
}

const Logged = (props) => <Fragment>
  <Header {...props} />
  <Community {...props} />
</Fragment>;

export default withSocket(App);