import React from 'react';
import ReactDOM from 'react-dom';

import Dorothy from 'dorothy-cli';

import tools from './tools';

import './custom-styles.css';

const API_URL = process.env.API_URL;

ReactDOM.render(
  <Dorothy url={API_URL} tools={tools} />, 
document.getElementById('root'));